

export const colorKamuaPink = '#DC00A3';
export const colorKamuaTeal = '#0082FF';
export const colorKamuaYellow = '#F9F871';
export const colorKamuaPurple = '#6E27CC';
export const colorPomegranate = '#f44336';
export const colorAmber = '#FFB300';
export const colorKamuaWhite = '#fcfbfe';

// Grayscale (white to black)
export const colorWhite = '#ffffff';
export const colorWhiteSmoke = '#f4f4f4';
export const colorLightGray = '#959595';
export const colorCharcoalGray = '#1D1F20';
export const colorCodGray = '#0D0D0D';
export const colorRealBlack = '#000000';
export const colorGray90 = '#191919';
export const colorBlack = colorCodGray;

// Functional colors
// export const colorPrimary = colorKamuaPink;
export const colorPrimary = colorKamuaTeal;
export const colorSecondary = colorKamuaPurple;
export const colorBackgroundDark = colorGray90;
export const colorTextDark = colorBlack;
export const colorBackgroundLight = colorKamuaWhite;
export const colorTextLight = colorWhite;
export const colorError = colorPomegranate;
export const colorWarning = colorAmber;

export type ColorString = string & { __COLOR_STRING__: void };

export type RGBColor = { [s in 'r' | 'g' | 'b']: number };
export type YUVColor = { [s in 'y' | 'u' | 'v']: number };

export const colorString2RGB = (color: string): RGBColor => {
  if (color[0] === '#') {
    // eslint-disable-next-line no-param-reassign
    color = color.substr(1);
  }

  const [, r, g, b] = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)!;

  return {
    r: parseInt(r, 16),
    g: parseInt(g, 16),
    b: parseInt(b, 16),
  };
};

export const colorRGB2YUV = (color: RGBColor): YUVColor => {
  const { r, g, b } = color;
  const y = Math.floor(r * 0.299000 + g * 0.587000 + b * 0.114000);
  const u = Math.floor(r * -0.168736 + g * -0.331264 + b * 0.500000 + 128);
  const v = Math.floor(r * 0.500000 + g * -0.418688 + b * -0.081312 + 128);

  return { y, u, v };
};

export const lerpColorComponent = (value: number, from: number, to: number, min = 0, max = 255) => {
  const range = max - min;
  const lerpRange = to - from;
  return {
    w: ((value / range) * lerpRange),
    b: from,
  };
};
