import { Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ShareIcon from '@material-ui/icons/Share';
import React from 'react';

import classes from './TinyVid.module.scss';

type Props = {
  downloadFunction: () => void,
  version?: string,
  size?: number,
};
const isMobile = window.innerWidth <= 767;

function shareMobile() {
  if (navigator.share) {
    navigator.share({
      title: 'TinyVid - The Free Video Compressor by Kamua',
      url: 'https://kamua.com/tinyvid',
    }).then(() => {
      // eventList.tinyVid.shared();
    })
      .catch(console.error);
  } else {

  }
}

function TinyVidDownload(props: Props) {
  const { downloadFunction, version, size } = props;

  if (isMobile) {
    return (
      <div className={classes.downloadContainer}>
        <p className={classes.downloadDescription}>
          TinyVid is currently only available for desktops and laptops (PCs, Macs. etc).<br />
          Help us spread the word so we can build you a mobile app too!
        </p>
        <Button
          className={classes.downloadButton}
          variant="contained"
          color="primary"
          onClick={shareMobile}
          size="large"
          startIcon={<ShareIcon />}
        >
          Share TinyVid
        </Button>
        <br />
        <small>* or share the link to your PC</small>
      </div>
    );
  }
  return (
    <div className={classes.downloadContainer}>
      <Button
        className={classes.downloadButton}
        variant="contained"
        color="primary"
        onClick={downloadFunction}
        size="large"
        startIcon={<GetAppIcon />}
      >
        Download TinyVid for FREE
      </Button>
      {version && size && (
        <div className={classes.downloadDetails}>

        </div>
      )}
    </div>
  );
}

export default React.memo(TinyVidDownload);
