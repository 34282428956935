/* eslint-disable */
import React from 'react';
import classNames from 'classnames';
import { Button, DialogContent, useTheme } from '@material-ui/core';
import Typed from 'typed.js';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import onLoad from './onLoad';
import './playVideoInView';

import classes from './Homepage.module.scss';
import VideoEmbedWithControls from './VideoEmbedWithControls';

const kamuaPromoVideo = {
  640: 'https://d2yveowal8ut5x.cloudfront.net/kamua-landing-480p.mp4',
  1280: 'https://d2yveowal8ut5x.cloudfront.net/kamua-landing-720p.mp4',
  1920: 'https://d2yveowal8ut5x.cloudfront.net/kamua-landing-1080p.mp4',
}
const poster = `https://i.vimeocdn.com/video/944243199.jpg?w=${Math.round(window.innerWidth)}`;

function HomePage() {
  const [isVideoModalOpen, setVideoModalOpen] = React.useState(false);

  const openVideoModal = React.useCallback(() => {
    setVideoModalOpen(true);
  }, [setVideoModalOpen]);

  const closeVideoModal = React.useCallback(() => {
    setVideoModalOpen(false);
  }, [setVideoModalOpen]);

  React.useEffect(() => {
    try {
      onLoad();
    } catch (err) {
      console.log('Error Caught for Video playback', err);
    }
    document.addEventListener('scroll', window.videoCheckHandler);
    window.videoCheckHandler();

    return () => {
      document.removeEventListener('scroll', window.videoCheckHandler)
    }
  }, []);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const setUpType = React.useCallback((ref: HTMLSpanElement) => {
    if (!ref) return;

    ref.innerText = '';

    const options = {
      strings: ['Resize', 'Cut', 'Caption'],
      typeSpeed: 150,
      backSpeed: 70,
      loop: true,
      smartBackspace: false,
    };

    // eslint-disable-next-line no-new
    new Typed('#fastest-feature-list', options);
  }, []);

  const socialMediaType = React.useCallback((ref: HTMLSpanElement) => {
    if (!ref) return;

    ref.innerText = '';

    const options = {
      strings: ['TikTok', 'Instagram', 'Snapchat', 'Facebook', 'Triller'],
      typeSpeed: 150,
      backSpeed: 70,
      loop: true,
      smartBackspace: false,
    };

    // eslint-disable-next-line no-new
    new Typed('#social-id', options);
  }, []);

  const fromProductHunt = false; // document.location.href.includes('ref=producthunt');
  const productHuntLaunch = new Date('Mon Mar 09 2021 10:00:00 GMT+0200');

  // product hunt IDs
  const featuredTestimonials = ['1290929', '1290934', '1290941', '1291380', '1290587', '1291066'];
  return (
    <>
      <div
        id="main"
        className={classNames(
          classes.section,
          classes.main,
          fromProductHunt && classes.withPHBanner,
        )}
      >
        <div className={classes.kamuaUvp}>
          <div className={classNames(classes.hero, classes.cta)}>
            <h1>
              The Fastest Way to <br />
              <span
                id="fastest-feature-list"
                className={classes.fastestFeatureList}
                ref={setUpType}
              >
                Resize
              </span>
              {' '}Video
            </h1>

            <div id="kamua-trailer-mobile" className={classes.kamuaTrailerMobile}>
              <VideoEmbedWithControls video={kamuaPromoVideo} poster={poster} />
            </div>

            <ul className={classes.listUVPs}>
              <li>Fully Browser Based</li>
              <li>You Work 50-90% Faster</li>
              <li>No Previous Experience Required</li>
              <li>Captions & Subtitles in 60 languages</li>
              <li>Ultra Fast Cloud Computing</li>
              <li>AI Automation of Hard Boring Tasks</li>
            </ul>

          </div>
        </div>
        <div id="kamua-trailer-desktop" className={classes.kamuaTrailer}>
          <VideoEmbedWithControls video={kamuaPromoVideo} poster={poster} />
        </div>
      </div>

      <div className={classNames(classes.section, classes.sectionCaptions)}>
        <div className={classes.content}>
          <h3 className={classes.subtitle}>
            AutoCaption your video for maximum engagement and accessibility
          </h3>
          <div>
            Automatically add subtitles to your{' '}
            <span className={classes.typingContainer}>
              <span id="social-id" ref={socialMediaType}>TikTok</span>
            </span>
            <br />videos in over 60 source languages.

            <ol style={{ textAlign: 'left' }}>
              <li>Add your video</li>
              <li>Select the source language</li>
              <li>Auto-process Captions</li>
              <li>Edit words, adjust fonts, size and positions</li>
              <li>Enjoy portrait optimized subtitles</li>
            </ol>

          </div>
          <p>Using Kamua you can <b>automate</b> that process in minutes.</p>
        </div>
        <div className={classes.contentVideo}>
          <h3 className={classes.subtitle}>
            AutoCaption your video for maximum engagement and accessibility
          </h3>
          <div className="kamua-captions">
            <video
              playsInline
              muted
              loop
              poster="https://i.vimeocdn.com/video/1079635813_600.jpg"
              src="https://player.vimeo.com/external/520992745.sd.mp4?s=8292be0813b8bb0b3fa9c308cb5b266b545b6862&profile_id=165"
              className={classes.rightImage}
            />
          </div>
        </div>
      </div>

      <div className={classNames(classes.section, classes.sectionPhonePreview)}>
        <div className={classes.contentVideo}>
          <h3 className={classes.subtitle}>
            Easily preview how your videos will look on any platform!
          </h3>
          <div className="preview-social-media">
            <video
              playsInline
              muted
              loop
              poster="https://i.vimeocdn.com/video/1079644508_600.jpg"
              src="https://player.vimeo.com/external/520990681.sd.mp4?s=cfe042491074d36746ebdef8a0638187bdc0b90b&profile_id=165"
            />
          </div>
        </div>
        <div className={classes.content}>
          <h3 className={classes.subtitle}>
            Easily preview how your videos will look on any platform!
          </h3>
          <p>
            We&apos;ve made it easy to easily preview how your
            video will look on your favorite social media platform,
            all while you&apos;re editing the video!
          </p>
          <p>
            No need to render, transfer to your phone and upload,
            just to see if things don&apos;t get in the way.
            We currently support previews for:
            TikTok, Facebook Stories, Instagram Reels,
            Instagram Stories, Snapchat, Pinterest Story Pins and Triller
          </p>
        </div>
      </div>

      <div className={classNames(classes.section, classes.sectionConvertVideos)}>
        <div className={classes.content}>
          <h3 className={classes.subtitle}>
            Automatically Convert YouTube videos to TikTok
          </h3>
          <p>You have spent time, money and effort growing your YouTube subscriber base. Now you
            need to get
            that YouTube content onto social to reach your potential audience there as well.
          </p>
          <p>With Kamua you can use <b>AutoCrop</b> to automatically convert those YouTube videos
            into
            vertical versions that work best with TikTok viewers.
          </p>
          <p>Kamua is a browser-based tool that automates the un-fun parts of video editing. No
            software
            downloads, no file synching, no editing experience, no big workstations required.
          </p>
          <Button
            href="https://kamua.com/blog/2021/01/12/youtube-to-tiktok/"
            variant="contained"
            color="secondary"
            className={classes.secondaryButton}
          >
            Read the how to: Youtube to Tiktok
          </Button>
          <div className={classes.watch} onClick={openVideoModal} id="watch-convert-videos">
            <img src="/icons/play_button_reverse.svg" alt="Play" className={classes.playIcon} />
            Watch how fast it can be done
          </div>
        </div>
        <div className={classes.contentVideo}>
          <h3 className={classes.subtitle}>
            Automatically Convert YouTube videos to TikTok
          </h3>

          <video
            playsInline
            muted
            loop
            poster="https://i.vimeocdn.com/video/869642589.jpg?w=600"
            src="https://player.vimeo.com/external/401044462.sd.mp4?s=deaad33774bfa5e720aa7675ba574714b7769df8&profile_id=165"
            className={classes.rightImage}
          />
        </div>
      </div>

      <div className={classNames(classes.section, classes.sectionPromoteBrand)}>
        <div className={classes.contentVideo}>
          <h3 className={classes.subtitle}>Promote your brand<br />Everywhere</h3>
          <div className="made-with-kamua">
            <video loop muted playsInline />
          </div>
        </div>
        <div className={classes.content}>
          <h3 className={classes.subtitle}>Promote your brand<br />Everywhere</h3>
          <p>You’ve shot amazing content in Widescreen (16:9) and now you need to get it out in
            Story format
            for IGTV, Snap, Facebook and so many others.
          </p>
          <p>Using Kamua you can <b>automate</b> that process in minutes.</p>
        </div>
      </div>

      <div className={classNames(classes.section, classes.sectionAutomateEdits)}>
        <div className={classes.content}>
          <h3 className={classes.subtitle}>Automate Video Edits</h3>
          <p>You and your teams spend hours scrubbing through videos to find cut points just so you
            can decide
            which shots, scenes and clips you want to use in your edit.
          </p>
          <p><b>AutoCut</b> by Kamua automatically cuts your video into its component shots, so you
            can get
            straight to the fun part of editing.
          </p>
        </div>
        <div className={classes.contentVideo}>
          <h3 className={classes.subtitle}>Automate Video Edits</h3>
          <video
            playsInline
            muted
            loop
            poster="https://i.vimeocdn.com/video/879193087?w=960"
            src="https://player.vimeo.com/external/407919916.sd.mp4?s=b421103f99343414238da9383329334271aa38d5&profile_id=165"
            className={classes.rightImage}
          />
        </div>
      </div>

      <div className={classNames(classes.section, classes.sectionNoEditExperience)}>
        <div className={classes.contentVideo}>
          <h3 className={classes.subtitle}>No Editing Experience? No worries!</h3>
          <img alt="Video" src="/img/noEditExpVideo.jpg" className={classes.leftImage} />
        </div>
        <div className={classes.content}>
          <h3 className={classes.subtitle}>No Editing Experience? No worries!</h3>
          <p>We have online video tutorials and a staff of friendly people who are here to guide you
            through
            the process of becoming a video ninja!
          </p>
          <p>Just click on the chat icon on the bottom right of the screen to talk to us about how
            we can help
            you.
          </p>
          <div className={classes.ninjaWatch}>
            <img src="/img/ninja.svg" alt="Ninja" className={classes.ninja} />
            <div className={classes.watch} onClick={openVideoModal} id="watch-no-edit-experience">
              <img src="/icons/play_button_reverse.svg" alt="Play" className={classes.playIcon} />
              Watch how easy Kamua is
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(classes.section, classes.sectionStunningAds)}>
        <div className={classes.content}>
          <h3 className={classes.subtitle}>Make Stunning Ads</h3>
          <p>There is nothing quite like the footage shot on a real camera, and that’s why TV and
            Web videos
            are made with them.
          </p>
          <p>Thankfully with Kamua you don’t have to sacrifice <b>quality</b> when you want to make
            video ads
            for apps that use vertical, square and other formats.
          </p>
          <p>Just upload or link your original footage and watch it transform!</p>
          <div className={classes.watch} onClick={openVideoModal} id="watch-stunning-ads">
            <img src="/icons/play_button_reverse.svg" alt="Play" className={classes.playIcon} />
            Watch how fast it can be done
          </div>
        </div>
        <div className={classes.contentVideo}>
          <h3 className={classes.subtitle}>Make Stunning Ads</h3>
          <video
            playsInline
            muted
            loop
            poster="https://i.vimeocdn.com/video/879195588?w=864"
            src="https://player.vimeo.com/external/408312591.sd.mp4?s=23281f516d7153652ddda8cfec50633fa4e49835&profile_id=165"
            className={classes.rightImage}
          />
        </div>
      </div>

      <div className={classNames(classes.section, classes.sectionDriveOnlineSales)}>
        <div className={classes.contentVideo}>
          <h3 className={classes.subtitle}>Drive Online Sales</h3>

          <video
            playsInline
            muted
            loop
            poster="https://i.vimeocdn.com/video/879193603?w=600"
            src="https://player.vimeo.com/external/407919832.sd.mp4?s=8975e653250d3c4a1a8b7bfe7f24657df3796729&profile_id=165"
            className={classes.leftImage}
          />
        </div>
        <div className={classes.content}>
          <h3 className={classes.subtitle}>Drive Online Sales</h3>
          <p>Video advertising is powering the boom in Ecommerce.</p>
          <p>With Kamua you can easily adapt your broadcast quality footage for use on Instagram,
            Facebook,
            YouTube, Snap and many other platforms where the format of the video is critical to
            maximizing
            the available screen space.
          </p>
          <p>Ask our team how you can quick repurpose your existing content to drive customers to
            your online
            shop.
          </p>
        </div>
      </div>

      <div className={classNames(classes.section, classes.sectionUseCase)}>
        <div className={classNames(classes.column, classes.leftColumn)}>
          <h3 className={classes.subtitle}>Use Case: 9 min Widescreen YouTube video to IG Story in
            19 min
            Edit
          </h3>

          <video
            playsInline
            muted
            loop
            poster="https://i.vimeocdn.com/video/879193978.jpg?w=600"
            src="https://player.vimeo.com/external/407919888.sd.mp4?s=48dc419499b3448a4da8642204ee7b6476e371e7&profile_id=165"
            className={classes.leftImage}
          />
        </div>
        <div className={classNames(classes.column, classes.rightColumn)}>
          <img alt="Convert" src="/img/convertYTInstagram.jpg" className={classes.rightImage} />
          <div>
            <p style={{ fontWeight: 600 }}>The Two Challenges</p>
            <p>The two main challenges with converting this video fast are: finding the 197 cut
              points, and,
              that a very small portion of the original footage can be preserved when cropping to
              9:16.
            </p>
            <p>It is therefore crucial to be able to follow the action on the screen and preserve
              the
              context at the same time. It’s easier when everything is in the center of the shot,
              but
              becomes increasingly hard when the subjects move laterally and the original camera
              pans and
              tracks as well.
            </p>
            <p style={{ fontWeight: 600 }}>AutoCrop to the Rescue</p>
            <p>Kamua has created a technology called AutoCrop. This uses Artificial Intelligence to
              assist
              you in tracking people and objects in each shot of the video. It can be manually
              overridden
              or re-targeted with 2 clicks.
            </p>
          </div>
        </div>
      </div>

      <div className={classNames(classes.section, classes.sectionTestimonial)}>
        <h3 className={classes.subtitle}>
          Testimonials
        </h3>

        <div className={classes.testimonialContainer}>
          {
            featuredTestimonials.map(id => (
              <div key={id} className={classes.itemTestimonialContainer}>
                <a
                  href={`https://www.producthunt.com/posts/kamua#comment-${id}`}
                  target="_blank"
                />
                <iframe
                  className={classes.productHuntTestimonial}
                  src={`https://cards.producthunt.com/cards/comments/${id}?v=1`}
                  width="330"
                  height="500"
                  frameBorder="0"
                  scrolling="no"
                  allowFullScreen
                />
              </div>
            ))
          }
        </div>
      </div>


      <div className={classNames(classes.section, classes.footer)}>
        <div className={classes.links}>
          <a href="https://kamua.com/blog/terms-of-use/">Terms of Use</a>
          <a href="https://kamua.com/blog/privacy-policy">Privacy Policy</a>
          <a href="https://kamua.com/blog/cookie-policy">Cookie Usage</a>
          <a href="mailto:hello@kamua.com">Contact Us!</a>
        </div>
      </div>
    </>
  );
}

export default React.memo(HomePage);
